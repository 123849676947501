<template>
    <div class="Bookings">
        Bookings
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Bookings',
}
</script>

<style scoped>

</style>
